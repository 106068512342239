import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { styleObject } from 'Style/type-helpers';

export const FLIP_IT_TEXT = '+ Flip it';

const StyledFlipIt = styled.div(
  {
    fontSize: '32px',
    color: '#9B9B9B',
    height: '55px',
    lineHeight: '55px',
    width: '60%',
    backgroundColor: '#E6E6E6',
    borderRadius: '3px',
    textAlign: 'center',
    maxWidth: '150px',
    a: {
      color: 'inherit',
    },
  },
  styleObject(
    BREAKPOINTS.tabletPortraitDown({
      fontSize: '24px',
      width: '70%',
      maxWidth: '115px',
    }),
  ),
);

const FlipIt: React.FC = ({ children }) => (
  <StyledFlipIt>{children}</StyledFlipIt>
);

export default FlipIt;
