import React from 'react';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { styleObject } from 'Style/type-helpers';

import codeSplitLoad from 'Webapp/shared/utils/code-split-load.js';
import PageClasses from 'Webapp/shared/app/components/page-classes';

import Button from 'Webapp/shared/app/components/button';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';
import FlipIt, {
  FLIP_IT_TEXT,
} from 'Webapp/shared/app/components/embed-tool/flip-it';

import { EmbedToolsContainerProps } from 'Webapp/shared/app/containers/embed-tool/embed-tools';
import connector from 'Utils/connector';
import connectFeatureFlags, {
  ConnectFeatureFlagsProps,
} from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectRouting, {
  ConnectRoutingProps,
} from 'Webapp/shared/app/connectors/connectRouting';
import withT from 'ComponentLibrary/hocs/withT';

import { EmbedToolModes } from 'Webapp/enums';
import {
  DIVIDER_COLORS,
  SURFACE_COLORS,
  TEXT_COLORS,
  COLORS,
} from 'Style/colors';
import { FONT_WEIGHTS } from 'Style/typography';
import { styleOnlyProps } from 'Style/style-helpers';
const MagazineAndStoryboardWidgetTool = codeSplitLoad(
  'MagazineAndStoryboardWidgetTool',
);
const LogoWidgetTool = codeSplitLoad('LogoWidgetTool');
const BrowserTools = codeSplitLoad('BrowserTools');

const LOGO_SIZE = {
  large: '270px',
  medium: '200px',
  small: '150px',
};

const EmbedToolsWrapper = styled.div({
  paddingTop: SPACING.XLARGE,
  display: 'grid',
  margin: SPACING.XLARGE,
});

const EmbedToolPicker = styled.ul(
  {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    borderBottom: `1px solid ${DIVIDER_COLORS.primary}`,
    paddingBottom: SPACING.BASE4X,
    marginBottom: SPACING.BASE6X,
    input: {
      '&:checked': {
        borderBottom: 'none',
        appearance: 'none',
        padding: SPACING.SMALL,
      },
    },
  },
  styleObject(
    BREAKPOINTS.phone({
      gridAutoFlow: 'row',
      gridTemplateColumns: 'repeat(1, 270px)',
      justifyContent: 'center',
    }),
  ),
);

const PickerTabButton = styled(Button, styleOnlyProps('selected'))<{
  selected: boolean;
}>(
  ({ selected }) => ({
    fontSize: '15px',
    width: LOGO_SIZE.large,
    padding: `${SPACING.MEDIUM} 0`,
    color: selected ? TEXT_COLORS.primary : TEXT_COLORS.dim,
    fontWeight: FONT_WEIGHTS.BOLD,
    textTransform: 'uppercase',
    whiteSpace: 'normal',
    '&:focus, &:hover': {
      color: TEXT_COLORS.primary,
      fontWeight: FONT_WEIGHTS.BOLD,
    },
  }),
  styleObject(
    BREAKPOINTS.tablet({
      width: LOGO_SIZE.medium,
    }),
  ),
  styleObject(
    BREAKPOINTS.tabletPortraitDown({
      width: LOGO_SIZE.small,
    }),
  ),
);

const LogoContainer = styled('div', styleOnlyProps('selected'))<{
  selected: boolean;
}>(
  {
    height: LOGO_SIZE.large,
    width: LOGO_SIZE.large,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: SURFACE_COLORS.tertiary,
    cursor: 'pointer',
  },
  styleObject(
    BREAKPOINTS.tablet({
      height: LOGO_SIZE.medium,
      width: LOGO_SIZE.medium,
    }),
  ),
  styleObject(
    BREAKPOINTS.tabletPortraitDown({
      height: LOGO_SIZE.small,
      width: LOGO_SIZE.small,
    }),
  ),
  ({ selected }) =>
    selected && {
      boxShadow: `inset 0 -15px 0 0 ${COLORS.red}`,
    },
);
const PickerTab = styled.li({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: SPACING.BASE,
  '&:last-child': {
    marginRight: '0',
  },
});

const EmbedToolBody = styled.div({
  height: 'fit-content',
  minHeight: '200px',
});

const StyledPreviewMagazineThumbnailImage = styled.img({
  height: '70%',
  width: '53%',
});

type EmbedToolsProps = {
  section?: Flipboard.Section;
  t: Flipboard.TFunction;
} & ConnectFeatureFlagsProps &
  ConnectRoutingProps &
  EmbedToolsContainerProps;

type EmbedToolsState = {
  embedToolMode: EmbedToolModes;
};

const PREVIEW_MAGAZINE_IMAGE_PLACEHOLDER =
  'https://cdn.flipboard.com/wp-content/uploads/2020/06/magazine-embed-thumbnail-example-5.png';

class EmbedTools extends React.Component<EmbedToolsProps, EmbedToolsState> {
  state = {
    embedToolMode: EmbedToolModes.MAGAZINE_STORYBOARD,
  };

  componentDidMount(): void {
    const {
      routing: {
        query: { tool },
      },
    } = this.props;

    if (!tool) {
      return;
    }

    if (EmbedToolModes[tool.toUpperCase()]) {
      this.setState({ embedToolMode: EmbedToolModes[tool.toUpperCase()] });
    }
  }

  setEmbedToolMode = (embedToolMode) => {
    this.setState({ embedToolMode });
  };

  renderToolPage = () => {
    const { embedToolMode } = this.state;
    const { section, primarySection } = this.props;

    switch (embedToolMode) {
      case EmbedToolModes.MAGAZINE_STORYBOARD: {
        const widgetSection = section || primarySection;

        return (
          <MagazineAndStoryboardWidgetTool
            section={
              (widgetSection?.isStoryboard || widgetSection?.isMagazine) &&
              widgetSection
            }
          />
        );
      }
      case EmbedToolModes.FOLLOW:
        return <LogoWidgetTool mode={embedToolMode} />;
      case EmbedToolModes.SHARE:
        return <LogoWidgetTool mode={embedToolMode} />;
      case EmbedToolModes.BROWSER:
        return <BrowserTools />;
    }
  };

  renderTabs = () => {
    const { embedToolMode } = this.state;
    const { t } = this.props;
    return Object.values(EmbedToolModes).map((mode) => {
      let logoPlaceHolder;
      let modeLabel;

      if (mode === EmbedToolModes.MAGAZINE_STORYBOARD) {
        logoPlaceHolder = (
          <StyledPreviewMagazineThumbnailImage
            src={PREVIEW_MAGAZINE_IMAGE_PLACEHOLDER}
            alt="magazine-embed-thumbnail"
          />
        );
        modeLabel = t('embed_tool_title');
      } else if (mode === EmbedToolModes.BROWSER) {
        logoPlaceHolder = <FlipIt>{FLIP_IT_TEXT}</FlipIt>;
        modeLabel = t('embed_tool_browser_button');
      } else if (mode === EmbedToolModes.FOLLOW) {
        logoPlaceHolder = <FlipboardLogo size={70} />;
        modeLabel = t('embed_tool_follow_button');
      } else {
        logoPlaceHolder = <FlipboardLogo size={70} />;
        modeLabel = t('embed_tool_share_button');
      }

      return (
        <PickerTab
          key={mode}
          onClick={() => {
            this.setEmbedToolMode(mode);
          }}
        >
          <LogoContainer selected={embedToolMode === mode}>
            {logoPlaceHolder}
          </LogoContainer>
          <PickerTabButton selected={embedToolMode === mode}>
            {modeLabel}
          </PickerTabButton>
        </PickerTab>
      );
    });
  };

  render() {
    return (
      <PageClasses>
        <EmbedToolsWrapper>
          <EmbedToolPicker>{this.renderTabs()}</EmbedToolPicker>
          <EmbedToolBody>{this.renderToolPage()}</EmbedToolBody>
        </EmbedToolsWrapper>
      </PageClasses>
    );
  }
}

export default connector(
  connectFeatureFlags,
  connectRouting,
)(withT(EmbedTools));
