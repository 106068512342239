import connector from 'Utils/connector';
import EmbedTools from 'Webapp/shared/app/components/embed-tool/embed-tools';
import { getPrimarySection } from 'Webapp/shared/app/redux/selectors/section';

const selectors = { primarySection: getPrimarySection };

export type EmbedToolsContainerProps = Flipboard.ConnectorProps<
  typeof selectors,
  unknown
>;

export default connector({ selectors })(EmbedTools);
